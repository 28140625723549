const validateZip = (regStr, value) => {
  const regular = new RegExp(regStr);
  if (!regular.test(value)) {
    return 'Postal code for this country is not valid';
  }
  return '';
};
/*
export const orderStatuses = () => [
  'Pending Payment',
  'Delivered',
  'Collections',
  'Completed',
  'Ready for Production',
  'In Production',
  'Shipped',
  'Sent to Supplier',
  'Sent to Fulfillment',
  'Pending Proof Approval',
  'Invoice Sent',
  'Presentation',
  'Request Hold',
  'New Order',
  'New Quote Request',
  'Cart',
  'Invoice Paid',
  'Canceled',
  'New Proof Request',
  'Proof In Process',
  'On Hold',
  'New Sample Request',
  'Deleted',
  'Archive',
];

export const artworkDocumentTypes = () => [
  'Production Ready',
  'Virtual Proof',
  'Supplier Art Template',
  'Non Production Ready',
];

export const addressType = () => ({
  distSupplier: ['Billing', 'Shipping'],
  customers: ['Business', 'Residential'],
});

export const contactType = () => ['Art', 'Billing', 'IT', 'Sales'];
const mapToArray = (map) => Object.keys(map).map((key) => map[key]);
export const creditStatus = () => ['Good Standing', 'Delinquent', 'None'];

export const noteType = () => ['Pricing', 'Billing', 'Shipping', 'General'];

export const roles = () => ['admin', 'user'];

export const serviceCode = () => ['ORDSTAT', 'INV', 'OSN', 'Product', 'PPC', 'MED', 'INVC', 'PO', 'SPCC'];

export const serviceName = () => [
  'Order Status',
  'Inventory',
  'Order Shipment Notification',
  'Product Data',
  'Product Pricing and Configuration',
  'Media Content',
  'Invoice',
  'Purchase Order',
  'Service Provider Customer Credentials',
];


export const orderType = () => ['Blank', 'Sample', 'Simple', 'Configured', 'Custom'];

export const ORDER_DOCUMENT_TYPES = {
  SUPPLIER_INVOICE: 'Supplier Invoice',
  CUSTOMER_PURCHASE_ORDER: 'Customer Purchase Order',
  SHIPPING_ACCOUNT_AUTHORIZATION: 'Shipping Account Authorization',
  INTERNATIONAL_SHIPPING_QUOTE: 'International Shipping Quote',
  LICENSE_AUTHORIZATION: 'License Authorization',
  ROYALTY_LETTER: 'Royalty Letter',
  CUSTOMER_CHECK: 'Customer Check',
};

export const orderDocumentTypes = () => mapToArray(ORDER_DOCUMENT_TYPES);
*/
export const orderCartStatuses = () => ['Cart', 'Proof Cart', 'Sample Cart', 'Quote Cart', 'Box Cart'];

export const ORDER_PAYMENT_TYPES = {
  CUSTOMER_PURCHASE_ORDER: 'Customer Purchase Order',
  CUSTOMER_CHECK: 'Customer Check',
  CREDIT_CARD: 'Credit Card',
};

export const zipValidators = () => [
  {
    country: 'Canada',
    validator(value) {
      return validateZip('^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$', value);
    },
  },
];

export const discountCodes = () => ({
  A: 0.5,
  P: 0.5,

  B: 0.45,
  Q: 0.45,

  C: 0.4,
  R: 0.4,

  D: 0.35,
  S: 0.35,

  E: 0.3,
  T: 0.3,

  F: 0.25,
  U: 0.25,

  G: 0.2,
  V: 0.2,

  '0': 0,
});

export const sortItemsList = (store) => {
  const baseOptions = {
    popular: { label: 'Most Popular', value: -1, type: 'popular', enabled: false },
    nameAsc: { label: 'A-Z', value: 1, type: 'name', enabled: false },
    nameDesc: { label: 'Z-A', value: -1, type: 'name', enabled: false },
    priceLowHigh: { id: 'cheapest', label: '$-$$$', value: 1, type: 'price', query: 'low-high', enabled: false },
    priceHighLow: { id: 'expensive', label: '$$$-$', value: -1, type: 'price', query: 'high-low', enabled: false },
    quantity: { label: 'Lowest Quantity', value: 1, type: 'quantity', enabled: false },
  };
  if (!store?.defaultSorting || store.defaultSorting.length === 0) {
    return Object.values(baseOptions);
  }
  const sortingConfig = store?.defaultSorting?.slice().sort((a, b) => a.position - b.position);
  const sortedAndEnabledOptions = sortingConfig
    ?.map((config) => {
      const option = baseOptions[config.id];
      return {
        ...option,
        enabled: config.enabled,
      };
    })
    ?.filter((option) => option && option.enabled);

  return sortedAndEnabledOptions;
};

export const orderedFacets = [
  {
    label: 'Eco Friendly',
    query: 'eco',
  },
  { label: 'Color' },
  { label: 'Brand' },
  { label: 'Material' },
  { label: 'Country of Origin', query: 'countryoforigin' },
  { label: 'Others' },
];

export const ADDITIONAL_CHARGE_TYPES = {
  'Payment Processing Fee': 1,
  'Late Fees': 2,
  MPower: 3,
  Other: 4,
  Shipping: 5,
  Fundraiser: 6,
};

export const CODE_MESSAGES = {
  pleaseEnterCode: 'Please enter a redemption code.',
  applied: 'This code is already applied to your account.',
  invalid: 'Invalid redemption code. Please try again.',
  limitReached: 'Item cannot be added to cart, because you have reached the maximum number of items.',
};
