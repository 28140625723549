import { render, staticRenderFns } from "./MPowerValidateInput.vue?vue&type=template&id=45a22aa8"
import script from "./MPowerValidateInput.vue?vue&type=script&lang=js"
export * from "./MPowerValidateInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MPowerValidateInputWrapper: require('/codebuild/output/src1947065313/src/client/components/MPowerValidateInputWrapper.vue').default})
